<style lang="scss" scoped>
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 77%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .pull-right {
    float: right;
    margin-top: -4%;
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    background: #1bc5bd;
    color: white;
    border-radius: 3px;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="lg"
    v-model="showModal"
    :title="
      is_edit && !is_view
        ? 'Update Workstation'
        : is_view
        ? 'View Workstation'
        : 'Create Workstation'
    "
  >
    <div class="row">
      <div class="col-12" style="padding: 10px">
        <label for="">Location <span class="text-danger">*</span></label>

        <multiselect
          :disabled="is_edit"
          key="id"
          :options="getBLocations.data ? getBLocations.data : []"
          placeholder="Select Location "
          label="name"
          track-by="id"
          :preselect-first="false"
          v-model="selected"
          @select="changeForm"
        ></multiselect>
        <span v-if="errors['client_id']" class="text-danger">{{
          errors["client_id"][0]
        }}</span>
        <!-- <input
          type="text"
          name=""
          class="form-control"
          id=""
          v-model="apikey.client_name"
          v-if="is_edit"
          disabled
        /> -->
      </div>
      <div class="col-6" style="padding: 10px">
        <div class="form-group">
          <label for=""
            >Workstation label <span class="text-danger">*</span></label
          >
          <input
            type="text"
            name=""
            :disabled="is_disabled"
            class="form-control"
            id=""
            v-model="apikey.label"
            :class="errors['label'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['label']" class="text-danger">{{
            errors["label"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px">
        <div class="form-group">
          <label for=""
            >Allowed IPS (CIDR) <span class="text-danger ml-0">*</span></label
          >
          <input
            :disabled="is_edit"
            type="text"
            name=""
            class="form-control ml-0"
            id=""
            v-model="apikey.ip_address"
            :class="errors['ip_address'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['ip_address']" class="text-danger">{{
            errors["ip_address"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6  mb-3">
        <label>Active</label>
        <span class="switch">
          <input
            type="checkbox"
            :disabled="is_disabled"
            v-model="apikey.is_active"
            class="switch"
            id="switch-normal"
          />
          <label for="switch-normal"></label>
        </span>
      </div>
      <div class="col-12 mb-3" style="padding: 10px" v-if="is_view">
        <label for="">Api Key </label>
        <div class="mt-1">
          <span ref="text">
            <strong style="font-size:16px; text-transform:uppercase;">{{
              apikey.api_key
            }}</strong>
          </span>
          <span class="ml-5">
            <button title="Copy to clipboard">
              <i class="la la-clone" style="font-size:2rem;" @click="copy"></i>
            </button>
          </span>
        </div>
        <!-- <input
          v-if="is_edit"
          type="text"
          name=""
          class="form-control"
          id=""
          v-model="apikey.api_key"
        /> -->

        <!-- <button class="btn btn-sm btn-outline-info text-white copy-btn ml-auto">
          Copy
        </button> -->
      </div>
    </div>
    <hr />
    <div v-if="showDoFrom" class="row">
      <div class="col-12">
        <h6>Department Order Submission config</h6>
      </div>

      <div class="col-6" style="padding: 10px">
        <div class="form-group">
          <label for="">LSID <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            :disabled="is_disabled"
            class="form-control"
            id=""
            v-model="apikey.do_lsid"
            :class="errors['do_lsid'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['do_lsid']" class="text-danger">{{
            errors["do_lsid"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px">
        <div class="form-group">
          <label for="">Username <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            :disabled="is_disabled"
            class="form-control"
            id=""
            v-model="apikey.do_username"
            :class="errors['do_username'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['do_username']" class="text-danger">{{
            errors["do_username"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px">
        <div class="form-group">
          <label for="">Host <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            :disabled="is_disabled"
            class="form-control"
            id=""
            v-model="apikey.do_host"
            :class="errors['do_host'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['do_host']" class="text-danger">{{
            errors["do_host"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px">
        <div class="form-group">
          <label for="">Port <span class="text-danger">*</span></label>
          <input
            type="number"
            name=""
            :disabled="is_disabled"
            class="form-control"
            id=""
            v-model="apikey.do_port"
            :class="errors['do_port'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['do_port']" class="text-danger">{{
            errors["do_port"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px">
        <div class="form-group">
          <label for=""
            >Nist file path <span class="text-danger">*</span></label
          >
          <input
            type="text"
            name=""
            :disabled="is_disabled"
            class="form-control"
            id=""
            v-model="apikey.do_nist_filepath"
            :class="errors['do_nist_filepath'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['do_nist_filepath']" class="text-danger">{{
            errors["do_nist_filepath"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px">
        <div class="form-group">
          <label for="">Sig file path <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            :disabled="is_disabled"
            class="form-control"
            id=""
            v-model="apikey.do_sig_filepath"
            :class="errors['do_sig_filepath'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['do_sig_filepath']" class="text-danger">{{
            errors["do_sig_filepath"][0]
          }}</span>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label>Key File <span class="text-danger">*</span></label>
          <span
            v-if="
              apikey.do_key_filepath != '' && apikey.do_key_filepath != null
            "
            class="ml-3"
            ><i class="la la-file-archive"></i>
            {{ apikey.do_key_filepath }}
          </span>

          <span
            style="margin-left: 2px"
            v-if="errors['do_key_filepath']"
            class="text-danger row"
            >{{ errors["do_key_filepath"][0] }}</span
          >

          <input
            type="file"
            id="file"
            ref="file"
            :disabled="is_disabled"
            v-on:change="handleFileUpload($event)"
          />
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100" v-if="!is_disabled">
        <b-button
          v-if="!is_edit"
          @click="save()"
          variant="success"
          size="sm"
          class="float-right ml-5"
        >
          Save
        </b-button>
        <b-button
          v-if="is_edit"
          @click="save()"
          variant="success"
          size="sm"
          class="float-right ml-5"
        >
          Update
        </b-button>
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
      <div class="w-100" v-if="is_disabled">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {} from "@/core/services/store/mutations.type";
import Multiselect from "vue-multiselect";
import {
  CREATE_APIKEY,
  FETCH_B_LOCATIONS,
  GENERATE_APIKEY,
  GET_APIKEY
} from "@/core/services/store/actions.type";
// import { json } from "body-parser";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    is_edit: {
      type: Boolean,
      default: false
    },
    is_disabled: {
      type: Boolean,
      default: false
    },
    is_view: {
      type: Boolean,
      default: false
    },
    apikeyid: {
      type: Number,
      default: 0
    },
    apikey: {
      type: Object,
      default: () => {
        return {
          id: 0,
          label: "",
          api_key: "",
          client_id: "",
          ip_address: "",
          is_active: 0,
          do_lsid: "",
          do_username: "",
          do_host: "",
          do_nist_filepath: "",
          do_sig_filepath: "",
          do_port: "",
          do_key_filepath: ""
        };
      }
    }
  },
  data() {
    return {
      errors: [],
      selected: "",
      key: 0,
      file: null,
      showDoFrom: false
    };
  },

  components: { Multiselect },
  computed: {
    ...mapGetters(["getBLocations"])
  },
  created() {
    this.getLocationData();
    if (this.is_edit) {
      this.getApikey();
    } else {
      this.selected = "";
    }
  },
  mounted() {
    document.body.addEventListener("keyup", e => {
      if (e.keyCode == 27) {
        this.$emit("closemodal");
      }
    });
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    copy() {
      this.selectText(this.$refs.text); // e.g. <div ref="text">
      document.execCommand("copy");
      this.$toastr.s("Api key copied successfully");
    },
    close() {
      this.$emit("closemodal");
    },
    async getApikey() {
      let res = await this.$store.dispatch(GET_APIKEY, this.apikeyid);
      this.selected = res[0].client;
      this.apikey.label = res[0].label;
      this.apikey.api_key = res[0].api_key;
      this.apikey.ip_address = res[0].ip_address;
      this.apikey.is_active = res[0].is_active;
      this.apikey.do_lsid = res[0].do_lsid;
      this.apikey.id = this.apikeyid;
      this.apikey.do_username = res[0].do_username;
      this.apikey.do_host = res[0].do_host;
      this.apikey.do_port = res[0].do_port;
      this.apikey.do_nist_filepath = res[0].do_nist_filepath;
      this.apikey.do_sig_filepath = res[0].do_sig_filepath;
      this.apikey.do_key_filepath = res[0].do_key_filepath;
      if (this.selected.show_apikey_deporderform) {
        this.showDoFrom = true;
      } else {
        this.showDoFrom = false;
      }
    },
    async genApiToken() {
      let res = await this.$store.dispatch(GENERATE_APIKEY);
      this.apikey.api_key = res.data;
      // formData.append('file', this.file[0]);
    },
    selectText(element) {
      var range;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
    changeForm(option) {
      if (option.show_apikey_deporderform) {
        this.showDoFrom = true;
      } else {
        this.showDoFrom = false;
      }
    },
    nameWithLang({ name }) {
      return `${name}`;
    },
    getLocationData() {
      this.loadingData = true;
      this.$store.dispatch(FETCH_B_LOCATIONS);
      this.key += 1;
    },
    save() {
      if (this.selected != "") {
        this.apikey.client_id = parseInt(this.selected.id);
      }
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("data", JSON.stringify(this.apikey));

      this.$store
        .dispatch(CREATE_APIKEY, formData)
        .then(() => {
          if (!this.is_edit) {
            this.$toastr.s("Api Key successfully created");
          } else {
            this.$toastr.s("Api Key successfully updated");
          }
          this.$emit("closemodal");
        })
        .catch(err => {
          this.errors = err;
        });
    },
    updateCanvasImage(e) {
      this.showImage = true;
      var self = this;

      var reader,
        files = e.target.files;

      reader = new FileReader();

      reader.onload = e => {
        this.e = e;
        var img = new Image();
        img.onload = function() {
          self.drawCanvasImage(img);
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(files[0]);
    },

    drawCanvasImage(imageObj) {
      // const canvas = document.createElement("canvas");

      // canvas.width = imageObj.width;
      // canvas.height = imageObj.height;

      // var context = canvas.getContext("2d");
      // // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      // context.drawImage(imageObj, 0, 0);

      // // var canvas = document.getElementById("imageCanvas");
      // var base64 = canvas.toDataURL("image/png");
      // this.image_base64 = base64;

      var canvas = this.$refs.imageCanvas;
      canvas.width = 120;
      canvas.height = (100 * imageObj.height) / imageObj.width;

      var context = canvas.getContext("2d");
      // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      context.drawImage(
        imageObj,
        0,
        0,
        120,
        (100 * imageObj.height) / imageObj.width
      );

      // var canvas = document.getElementById("imageCanvas");
      var base64 = canvas.toDataURL("image/png");
      this.group.image = base64;
    }
  }
};
</script>
