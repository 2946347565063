<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <select
              @keypress.enter="loadApiData()"
              v-model="serviceOutlet"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Location</option>
              <option
                v-for="(citem, index) in getClients"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <button class="btn btn-outline-info" @click="loadApiData()">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
        <div class="col-md-5"></div>
        <div class="col-md-2">
          <button class="btn btn-info" @click="showModal">
            <i class="fa fa-plus"></i>Create Workstation
          </button>
        </div>
      </div>
      <div class="col-md-12" style="background:white">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>SN</th>
              <th>Workstation Label</th>
              <th>Token</th>
              <th>Device ID</th>
              <th>Serial Number</th>
              <th>Allowed IPS</th>
              <th>Status</th>
              <th style="width:20%">Location</th>
              <th style="width:20%">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loadingData && getApikeyData.data.length > 0">
            <tr v-for="(item, index) in getApikeyData.data" :key="index">
              <td>{{ ++index }}</td>
              <td>{{ item.label }}</td>
              <td>{{ item.api_key }}</td>
              <th>{{ item.local_device_id }}</th>
              <th>{{ item.serial_number }}</th>
              <td>{{ item.ip_address }}</td>
              <td>
                <i class="fa fa-times text-danger" v-if="!item.is_active"></i>
                <i class="fa fa-check text-success" v-if="item.is_active"></i>
              </td>
              <td>{{ item.client_name }}</td>

              <td style="width:100px">
                <!-- <button
                  class="btn btn-sm btn-primary mr-3 mt-2"
                  style="cursor: pointer;"
                  @click="gotoGroupService(item.id)"
                >
                  Assign service
                </button> -->
                <button
                  @click="view(item)"
                  class="btn btn-sm btn-primary mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  View
                </button>
                <button
                  class="btn btn-sm btn-info mr-3 mt-2"
                  style="cursor: pointer;"
                  @click="gotoApiKeyDevices(item.id)"
                >
                  Devices
                </button>
                <button
                  @click="edit(item)"
                  class="btn btn-sm btn-success mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Edit
                </button>

                <button
                  @click="apidelete(item)"
                  class="btn btn-sm btn-danger mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!loadingData && getApikeyData.data.length < 1">
            <tr>
              <td colspan="8" class="text-center">No record(s) found</td>
            </tr>
          </tbody>

          <tbody v-if="loadingData">
            <tr>
              <th class="text-center" colspan="10">
                <vDataLoader></vDataLoader>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <hr v-if="pagination.total_page >= 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page >= 1"
        :pagination="pagination"
      ></vl-pagination>
      <apikey
        :key="key"
        v-if="ModalShow"
        v-on:closemodal="closeModal"
        :show-modal="ModalShow"
        :apikey="apikeyData"
        :is_edit="is_edit"
        :apikeyid="apikeyid"
        :is_view="is_view"
        :is_disabled="is_disabled"
      ></apikey>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_APIKEY,
  CREATE_APIKEY,
  DELETE_APIKEY,
  FETCH_CLIENTS
} from "@/core/services/store/actions.type";
import apikey from "@/pages/backend/apikey/apikeymodal";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vlPagination from "@/includes/table/Pagination";

export default {
  computed: {
    ...mapGetters(["currentUser", "getApikeyData", "getClients"])
  },
  mounted() {
    this.pagination = {
      total_records: 0,
      total_page: 0,
      current_page: 1,
      per_page_data: 1,
      received_per_page_data: 1
    };
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.loadClients();
    this.loadApiData();
  },
  components: {
    apikey,
    vlPagination
  },
  methods: {
    async loadApiData() {
      let page = this.pagination.current_page;

      await this.$store
        .dispatch(FETCH_APIKEY, {
          service_outlet: this.serviceOutlet,
          page: page
        })
        .then(res => {
          this.pagination.total_records = res.total_record;
          this.pagination.total_page = res.page_range;
          this.pagination.received_per_page_data = res.data.length;
          this.pagination.per_page_data = 1;
        });
    },
    showModal() {
      this.key += 1;

      this.apikeyData = {
        id: 0,
        label: "",
        api_key: "",
        client_id: "",
        ip_address: "",
        is_active: 0,
        do_username: "",
        do_lsid: "",
        do_host: "",
        do_port: "",
        do_nist_filepath: "",
        do_sig_filepath: ""
      };
      this.ModalShow = true;
      this.is_edit = false;
      this.is_view = false;
      this.is_disabled = false;
    },
    closeModal() {
      this.key += 1;

      this.ModalShow = false;
      this.is_view = false;
      this.is_edit = false;
      this.is_disabled = false;
      this.loadApiData();
    },
    async loadClients() {
      await this.$store.dispatch(FETCH_CLIENTS);
    },
    edit(item) {
      this.key += 1;

      this.ModalShow = true;
      this.is_edit = true;
      this.is_view = false;
      this.is_disabled = false;

      this.apikeyid = item.id;
    },
    view(item) {
      this.key += 1;

      this.ModalShow = true;
      this.is_edit = true;
      this.is_view = true;
      this.is_disabled = true;

      this.apikeyid = item.id;
    },
    apidelete(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_APIKEY, item.id).then(() => {
            this.$toastr.s("ApiKey Successfully Deleted ");
            this.pagination = {
              total_records: 0,
              total_page: 0,
              current_page: 1,
              per_page_data: 15,
              received_per_page_data: 15
            };
            this.loadApiData();
          });
        }
      });
    },
    gotoApiKeyDevices(id) {
      this.$router.push({ name: "client.apikey.devices", params: { id: id } });
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      this.loadApiData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.loadApiData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.loadApiData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.loadApiData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.loadApiData();
    },
    update(item) {
      let data = { ...item };
      this.$store
        .dispatch(CREATE_APIKEY, data)
        .then(() => {
          this.$toastr.s("ApiKey Successfully Updated ");
          this.loadApiData();
        })
        .catch(err => {
          this.errors = err;
        });
    }
    // deleteItem(id) {
    //   this.$store
    //     .dispatch(DELETE_APIKEY, id)
    //     .then(() => {
    //       this.$toastr.s("Group Deleted ");
    //       this.$store.dispatch(FETCH_APIKEY);
    //     })
    //     .catch(err => {
    //       this.errors = err;
    //     });
    // },
  },
  data() {
    return {
      loadingData: false,
      ModalShow: false,
      apikey: [],
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      },
      is_view: false,
      is_disabled: false,
      key: 0,
      serviceOutlet: "all",
      columns: [
        {
          name: "Apikey name",
          width: "",
          label: "name",
          class: ""
        }
      ],
      is_edit: false,
      apikeyData: {
        id: 0,
        label: "",
        api_key: "",
        client: "",
        ip_address: false,
        is_active: 0,
        do_username: "",
        do_host: "",
        do_port: "",
        do_nist_filepath: "",
        do_sig_filepath: "",
        do_key_filepath: ""
      },
      apikeyid: 0,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Workstation List",
          route: ""
        }
      ]
    };
  }
};
</script>
